import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const app = initializeApp({
    apiKey: "AIzaSyAyikumAews0d9dVHT_Ts171hu_Z4PPy6o",
    authDomain: "lympeimmo.firebaseapp.com",
    projectId: "lympeimmo",
    storageBucket: "lympeimmo.appspot.com",
    messagingSenderId: "829179967007",
    appId: "1:829179967007:web:a81deb1729a997c6f9472a",
    measurementId: "G-9M36LX5W33"
});

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
