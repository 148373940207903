import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';


import { firestore } from '../utils/firebase'
import { collection, getDocs } from 'firebase/firestore'

const TenantsPage = () => {
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        getDocs(collection(firestore, 'tenants')).then(res => {
            const newTenants = [];
            res.docs.forEach(doc => {
                newTenants.push({...doc.data(), tenantId: doc.id})
            });

            setTenants(newTenants)
            console.log("Tenants: ", newTenants)
            setLoading(false)
        });
    }, []);

    return (
        <div className="grid">
             <div className="col-12">
                <div className="card">

                    <h5>Mes locataires</h5>
                
                    <DataTable value={tenants} scrollable scrollHeight="400px" loading={loading} scrollDirection="both" className="mt-3">
                        <Column field="name" header="Nom" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="email" header="Email" style={{ flexGrow: 1, flexBasis: '160px' }}></Column>
                        <Column field="phoneNumber" header="phoneNumber" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column header="Actions" style={{width:'15%'}} body={() => (
                            <>
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Supprimer"/>
                            </>
                        )}/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TenantsPage, comparisonFn);