import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';


import { ToggleButton } from 'primereact/togglebutton';

import { firestore } from '../utils/firebase'
import { collection, getDocs } from 'firebase/firestore'

const HousesPage = () => {

    const [rentFrozen, setRentFrozen] = useState(false);
    const [houses, setHouses] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        getDocs(collection(firestore, 'houses')).then(res => {
            const newHouses = [];
            res.docs.forEach(doc => {
                newHouses.push({...doc.data(), houseId: doc.id})
            });

            setHouses(newHouses)
            console.log("HOUSES: ", newHouses)
            setLoading(false)
        });
    }, []);

    return (
        <div className="grid">
             <div className="col-12">
                <div className="card">

                    <h5>Mes logements</h5>
                    <ToggleButton checked={rentFrozen} onChange={(e) => setRentFrozen(e.value)} onIcon="pi pi-lock" offIcon="pi pi-lock-open" onLabel="Débloquer Loyer" offLabel="Bloquer Loyer" style={{ width: '10rem' }} />

                    <DataTable value={houses} scrollable scrollHeight="400px" loading={loading} scrollDirection="both" className="mt-3">
                        <Column field="displayName" header="Nom du logement" style={{ flexGrow: 1, flexBasis: '160px' }} frozen></Column>
                        <Column field="rent" header="Loyer" style={{ flexGrow: 1, flexBasis: '160px' }} frozen={rentFrozen}></Column>
                        <Column field="address" header="Adresse" style={{ flexGrow: 1, flexBasis: '160px' }} ></Column>
                        <Column header="Actions" style={{width:'15%'}} body={() => (
                            <>
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Supprimer"/>
                            </>
                        )}/>
                       
                       
                       
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(HousesPage, comparisonFn);
