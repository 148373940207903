import React from 'react';
import { Switch } from 'react-router-dom';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';

import { AuthenticatedRoute } from './routes/AuthenticatedRoute';
import DashboardPage from "./pages/DashboardPage";
import SignInPage from "./pages/SignInPage";
import {UnauthenticatedRoute} from "./routes/UnauthenticatedRoute";

const App = () => {
    PrimeReact.ripple = true;

    return (
        <div>
            <Switch>
                <UnauthenticatedRoute path="/login" component={SignInPage} />
                <AuthenticatedRoute path="/" component={DashboardPage} />
            </Switch>
        </div>
    );

}

export default App;
