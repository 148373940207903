import React, {useCallback} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../utils/firebase';

const SignInPage = () => {

    const handleSubmit = useCallback(async e => {
        e.preventDefault()

        const { email, password } = e.target.elements
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value)
        } catch (e) {
            alert(e.message)
        }
    }, [])

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <form onSubmit={handleSubmit}>
                    <div className="card p-fluid">
                        <h5>Sign in</h5>
                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" type="text" />
                        </div>
                        <div className="field">
                            <label htmlFor="password">Password</label>
                            <InputText id="password" type="password" />
                        </div>

                        <Button label="Sign in" className="mr-2 mb-2"></Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SignInPage, comparisonFn);
